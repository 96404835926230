module.exports = {
  path: "/layout",
  name: "layout",
  component: () => import("@/views/LayoutView/LayoutView"),
  children: [
    {
      path: "userlist",
      name: "userlist",
      component: () => import("@/views/UserView/UserList"),
      meta:{
        isLogin:true
      }
    },
    // {
    //   path: "useradd",
    //   name: "useradd",
    //   component: () => import("@/views/UserView/UserAdd"),
    //   meta:{
    //     isLogin:true
    //   }
    // },
  ]

}