import Vue from 'vue'
import VueRouter from 'vue-router'
import UserRouter from "./module/user"
// import HomeRouter from "./module/home"
// import TextRouter from "./module/text"
Vue.use(VueRouter)

const routes = [
    {
      name:"login",
      path:"/login",
      component:()=>import("@/views/LoginView/LoginView")
    },
    UserRouter,
   {
    path:"*",
     redirect:"/login"
   }
]

const router = new VueRouter({
  routes,
  mode:"hash"
});
router.beforeEach((to,from,next)=>{
  if(to.meta.isLogin && !localStorage.getItem("token")){
    router.push({
      name:"login",
      path:"/login"
    })
  }else{
    next()
  }
})

export default router
